import React from 'react';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import { Field, Form, Formik, validateYupSchema, yupToFormErrors } from 'formik';
import Grid from '@material-ui/core/Grid';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import FieldLabel from '../../quickApplications/CreateQuickApplication/components/FieldLabel';
import MaterialInput from '../../quickApplications/CreateQuickApplication/components/MaterialInput';
import { apvgkService } from '../../../../services/apvgkService';
import MaterialSelect from '../../quickApplications/CreateQuickApplication/components/MaterialSelect';
import { dictionaryHelper } from '../../../../helpers/dictionaryHelper';
import MaterialCheckbox from "../../quickApplications/CreateQuickApplication/components/MaterialCheckbox";

const Section = ({ title, children }) => (
  <React.Fragment>
    <Grid item>
      <h3 className="h3-title">{title}</h3>
    </Grid>
    {children}
  </React.Fragment>
);

const equipmentOptions = dictionaryHelper.equipmentTypes.getList().map(item => ({
  value: item.id,
  label: item.title
}));
const roadTypeOptions = [
  { value: 0, label: 'региональная' },
  { value: 1, label: 'федеральная' },
  { value: 2, label: 'муниципальная' }
];

export const Schema = yup.object().shape({
  name: yup.string().required('Обязательное поле'),
  equipment_name: yup.string().required('Обязательное поле'),
  code: yup.string().required('Обязательное поле'),
  equipment_type: yup.string().required('Обязательное поле'),
  owner: yup.string().required('Обязательное поле'),
  rdm_number: yup.string().required('Обязательное поле'),
  district: yup.string().required('Обязательное поле'),
  latitude: yup.string().required('Обязательное поле'),
  longitude: yup.string().required('Обязательное поле'),
  certificate_number: yup.string().required('Обязательное поле'),
  certificate_expired_date: yup.date().required('Обязательное поле'),
  place_check_number: yup.string().required('Обязательное поле'),
  place_check_date: yup.date().required('Обязательное поле'),
  verification_number: yup.string().required('Обязательное поле'),
  verification_date: yup.date().required('Обязательное поле'),
  verification_expired_date: yup.date().required('Обязательное поле'),
  road_type: yup.string().required('Обязательное поле'),
  road_number: yup.string().required('Обязательное поле'),
  road_place: yup.string().required('Обязательное поле'),
  road_direction1_name: yup.string().required('Обязательное поле'),
  road_direction2_name: yup.string().required('Обязательное поле'),
  road_load: yup.string().required('Обязательное поле'),
  ftp_login: yup.string().required('Обязательное поле'),
  ftp_address: yup.string().required('Обязательное поле'),
  ftp_password: yup.string().required('Обязательное поле')
});

const ApvgkModal = ({ isShow, initialData, onClose }) => (
  <Dialog open={isShow} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth="md">
    <Toolbar className="text-center">
      <Typography variant="title" color="inherit" className="flex">
        {initialData ? 'Редактирование' : 'Создание'}
      </Typography>
      <IconButton color="inherit" onClick={onClose}>
        <CloseIcon className="blue" />
      </IconButton>
    </Toolbar>

    <DialogContent className="dialog">
      <Formik
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          try {
            if (initialData) {
              const newData = await apvgkService.update(data);
              onClose(newData);
            } else {
              await apvgkService.create(data);
              onClose();
            }
          } catch (e) {
            console.log(e);
          }
          setSubmitting(false);
        }}
        isInitialValid
        initialValues={
          initialData
            ? {
                ...initialData,
                certificate_expired_date: moment(
                  initialData.certificate_expired_date,
                  'YYYY-MM-DD hh:mm:ss'
                ).format('YYYY-MM-DD'),
                place_check_date: moment(
                  initialData.place_check_date,
                  'YYYY-MM-DD hh:mm:ss'
                ).format('YYYY-MM-DD'),
                verification_date: moment(
                  initialData.verification_date,
                  'YYYY-MM-DD hh:mm:ss'
                ).format('YYYY-MM-DD'),
                verification_expired_date: moment(
                  initialData.verification_expired_date,
                  'YYYY-MM-DD hh:mm:ss'
                ).format('YYYY-MM-DD')
              }
            : {}
        }
        validate={async values => {
          return new Promise((resolve, reject) => {
            validateYupSchema(values, Schema, false).then(
              () => resolve({}),
              err => reject(yupToFormErrors(err))
            );
          });
        }}
      >
        {({ values, isValid, isSubmitting, setFieldValue }) => (
          <Form>
            <Grid container direction="column" spacing={16} wrap="nowrap">
              <Grid item>
                <FieldLabel label="Наименование АПВГК*" name="name">
                  <Field name="name" component={MaterialInput} />
                </FieldLabel>
              </Grid>
              <Grid item>
                <FieldLabel label="Наименование оборудования*" name="equipment_name">
                  <Field name="equipment_name" component={MaterialInput} />
                </FieldLabel>
              </Grid>
              <Grid item>
                <FieldLabel label="Код*" name="code">
                  <Field name="code" component={MaterialInput} />
                </FieldLabel>
              </Grid>
              <Grid item>
                <FieldLabel label="Заводской номер*" name="equipment_seria_number">
                  <Field name="equipment_seria_number" component={MaterialInput} />
                </FieldLabel>
              </Grid>
              <Grid item>
                <FieldLabel label="РДМ номер*" name="rdm_number">
                  <Field name="rdm_number" component={MaterialInput} />
                </FieldLabel>
              </Grid>
              <Grid item>
                <FieldLabel label="Тип оборудования*" name="equipment_type">
                  <Field
                    name="equipment_type"
                    placeholder="Выберите тип оборудования"
                    component={MaterialSelect}
                    options={equipmentOptions}
                  />
                </FieldLabel>
              </Grid>
              <Grid item>
                <FieldLabel label="Владелец*" name="owner">
                  <Field name="owner" component={MaterialInput} />
                </FieldLabel>
              </Grid>
              <Grid item>
                <FieldLabel label="Муниципальный район РТ*" name="district">
                  <Field name="district" component={MaterialInput} />
                </FieldLabel>
              </Grid>
              <Section title="Георгафические координаты">
                <Grid item>
                  <FieldLabel label="Широта*" name="latitude">
                    <Field name="latitude" component={MaterialInput} />
                  </FieldLabel>
                </Grid>
                <Grid item>
                  <FieldLabel label="Долгота*" name="longitude">
                    <Field name="longitude" component={MaterialInput} />
                  </FieldLabel>
                </Grid>
              </Section>
              <Section title="Свидетельство об утверждении типа измерения">
                <Grid item>
                  <FieldLabel label="Номер*" name="certificate_number">
                    <Field name="certificate_number" component={MaterialInput} />
                  </FieldLabel>
                </Grid>
                <Grid item>
                  <FieldLabel label="Срок действия*" name="certificate_expired_date">
                    <Field
                      name="certificate_expired_date"
                      component={MaterialInput}
                      type="date"
                      required="required"
                    />
                  </FieldLabel>
                </Grid>
              </Section>
              <Section title="Свидетельство о поверке оборудования">
                <Grid item>
                  <FieldLabel label="Номер*" name="verification_number">
                    <Field name="verification_number" component={MaterialInput} />
                  </FieldLabel>
                </Grid>
                <Grid item>
                  <FieldLabel label="Дата осуществления*" name="verification_date">
                    <Field
                      name="verification_date"
                      component={MaterialInput}
                      type="date"
                      required="required"
                    />
                  </FieldLabel>
                </Grid>
                <Grid item>
                  <FieldLabel label="Срок действия*" name="verification_expired_date">
                    <Field
                      name="verification_expired_date"
                      component={MaterialInput}
                      type="date"
                      required="required"
                    />
                  </FieldLabel>
                </Grid>
              </Section>
              <Section title="Информация о проверке соотвествия места установки">
                <Grid item>
                  <FieldLabel label="Номер акта*" name="place_check_number">
                    <Field name="place_check_number" component={MaterialInput} />
                  </FieldLabel>
                </Grid>
                <Grid item>
                  <FieldLabel label="Дата осуществления*" name="place_check_date">
                    <Field
                      name="place_check_date"
                      component={MaterialInput}
                      type="date"
                      required="required"
                    />
                  </FieldLabel>
                </Grid>
              </Section>
              <Section title="Информация о дороге">
                <Grid item>
                  <FieldLabel label="Значение*" name="road_type">
                    <Field
                      name="road_type"
                      component={MaterialSelect}
                      options={roadTypeOptions}
                      placeholder="Выберите тип дороги"
                    />
                  </FieldLabel>
                </Grid>
                <Grid item>
                  <FieldLabel label="Номер дороги*" name="road_number">
                    <Field name="road_number" component={MaterialInput} />
                  </FieldLabel>
                </Grid>
                <Grid item>
                  <FieldLabel label="Место фиксации*" name="road_place">
                    <Field name="road_place" component={MaterialInput} />
                  </FieldLabel>
                </Grid>
                <Grid item>
                  <FieldLabel label="Направление 1*" name="road_direction1_name">
                    <Field name="road_direction1_name" component={MaterialInput} />
                  </FieldLabel>
                </Grid>
                <Grid item>
                  <FieldLabel label="Направление 2*" name="road_direction2_name">
                    <Field name="road_direction2_name" component={MaterialInput} />
                  </FieldLabel>
                </Grid>
                <Grid item>
                    <Section title="Направление 2* соответствует направлению 'от 0 км'">
                        <Grid item>
                            <FieldLabel label="Соответствует" name="direction_reverse">
                                <Field name="direction_reverse" component={MaterialCheckbox}/>
                            </FieldLabel>
                        </Grid>
                    </Section>
                </Grid>
                <Grid item>
                  <FieldLabel
                    label="Максимально допустимая нагрузка на одиночную ось*"
                    name="road_load"
                  >
                    <Field name="road_load" component={MaterialInput} />
                  </FieldLabel>
                </Grid>
              </Section>
              <Section title="Доступ к ftp">
                <Grid item>
                  <FieldLabel label="ftp*" name="ftp_address">
                    <Field name="ftp_address" component={MaterialInput} />
                  </FieldLabel>
                </Grid>
                <Grid item>
                  <FieldLabel label="Логин*" name="ftp_login">
                    <Field name="ftp_login" component={MaterialInput} />
                  </FieldLabel>
                </Grid>
                <Grid item>
                  <FieldLabel label="Пароль*" name="ftp_password">
                    <Field name="ftp_password" component={MaterialInput} type="password" />
                  </FieldLabel>
                </Grid>
              </Section>
                {initialData && <>
                    <Section title="ЦАФАП">
                        <Grid item>
                            <FieldLabel label="Отправлять подписанные акты" name="cafap_enable">
                                <Field name="cafap_enable" component={MaterialCheckbox}/>
                            </FieldLabel>
                        </Grid>
                    </Section>
                    <Section title="Весеннее ограничение">
                        <Grid item>
                            <FieldLabel label="Включено" name="spring_enable">
                                <Field name="spring_enable" component={MaterialCheckbox}/>
                            </FieldLabel>
                        </Grid>
                    </Section>
                  <Section title="Прочее">
                    <Grid item>
                      <FieldLabel label="Учитывать превшения габаритов" name="dimensions_enable">
                        <Field name="dimensions_enable" component={MaterialCheckbox}/>
                      </FieldLabel>
                    </Grid>
                  </Section>
                </>}
              <Grid item>
                <Button className="btn" disabled={isSubmitting || !isValid} type="submit">
                  {initialData ? 'Редактировать' : 'Создать'}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </DialogContent>
  </Dialog>
);

export default ApvgkModal;
