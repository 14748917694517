import React from 'react';
import DictionariesPage from './dictionaries/DictionariesPage';
import { AdminRoute } from '../../../components/routes/AdminRoute';
import CoefficientsPage from './coefficient/CoefficientsPage';
import RouteAddressesPage from './address/RouteAddressesPage';
import { AdminPanelMenu } from './AdminPanelMenu';
import StatusesLayout from './status/StatusesLayout';
import { SpringPage } from './spring/SpringPage';
import ApvgkPanel from './apvgk';

const PanelLayout = props => {
  const { match } = props;
  const panelMenuLinks = [
    { key: 1, path: `${match.url}`, title: 'Справочники' },
    { key: 2, path: `${match.url}/coefficients`, title: 'Коэффициенты' },
    { key: 3, path: `${match.url}/statuses`, title: 'Статусы' },
    { key: 4, path: `${match.url}/spring`, title: 'Допустимые нагрузки' },
    { key: 5, path: `${match.url}/addresses`, title: 'Адреса' },
    { key: 6, path: `${match.url}/apvgk`, title: 'АПВГК' }
  ];

  return (
    <main role="main" className="container">
      <div className="kyc">
        <h2 className="h2-title">Панель администратора</h2>

        <AdminPanelMenu links={panelMenuLinks} />

        <AdminRoute exact path={`${match.url}`} component={DictionariesPage} />
        <AdminRoute path={`${match.url}/coefficients`} component={CoefficientsPage} />
        <AdminRoute path={`${match.url}/statuses`} component={StatusesLayout} />
        <AdminRoute path={`${match.url}/spring`} component={SpringPage} />
        <AdminRoute path={`${match.url}/addresses`} component={RouteAddressesPage} />
        <AdminRoute path={`${match.url}/apvgk`} component={ApvgkPanel} />
      </div>
    </main>
  );
};

export default PanelLayout;
